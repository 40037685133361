
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of meetings</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportClients()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <b-button variant="primary" class="" @click.prevent="popupModalShow = true"
          type="button">  <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Create a Meeting</span> </b-button>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-slot:cell(check)="data">
                  <div style="width:3.5rem;" class="form-check text-center me-auto">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input me-2" :id="`tableSRadio${data.item.id}`" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(id)="data">
                  <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.friendly_id }}
                  </div>
                </template>
                <template v-slot:cell(title)="data">
                  <div style="min-width:9rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.title }}
                  </div>
                </template>
                <template v-slot:cell(started_at)="data">
                  <div style="min-width:6rem;width:6rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    {{$filters.date(data.item.started_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(ended_at)="data">
                  <div style="min-width:6rem;width:6rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    {{$filters.date(data.item.ended_at, 1) }}
                  </div>
                </template>
                <template v-slot:cell(guests)="data">
                  <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.guests_count }}</span>
                  </div>
                </template>
                <template v-slot:cell(date)="data">
                  <div @click="navigateTo(data.item.id)" 
                  style="min-width:6rem;" class="cursor-pointer">
                    {{$filters.date(data.item.created_at, 2) }} 
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      title="Add meeting"
      title-class="fs-6" @hide="initializeAll()">
      <meeting-form v-if="popupModalShow" @closeMe="initializeAll()" />
    </b-modal>
  </div>
</template>

<script>
import MeetingForm from '@/components/forms/MeetingForm.vue';
import IsLoading from '@/components/IsLoading.vue';
import _ from 'lodash';

export default {
  name: "meeting-list",
  components:{
    IsLoading,
    MeetingForm
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      defaultStatus: null,
      searchQuery:"",
      statusOptions:[
        { value: null, label: "All", },
        { value: "pending", label: "Pending" },
        { value: "ongoing", label: "Ongoing" },
        { value: "closed", label: "Closed" },
        { value: "canceled", label: "Canceled" }
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      isSelectAll: false,
      columns: [
        {
            key: "check",
            label: "Check"
        },
        {
            key: "id",
            label: "ID",
        },
        {
            key: "title",
            label: "Title",
        },
        {
            key: "started_at",
            label: "Start Date",
        },
        {
            key: "ended_at",
            label: "End Date",
        },
        {
            key: "guests",
            label: "Guests",
        },
        {
            key: "date",
            label: "Created at",
        }
      ],
      selected: [],
    }
  },
  watch: {
    defaultStatus (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.meeting.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/meetings/${id}`});
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.meetings.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("meeting/fetchMeetings", payload)
      .then(response => this.isLoading = false)
    },
    exportClients() {
      const payload = {
        url: '/meetings/export-excel',
        filename: 'meetings_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

